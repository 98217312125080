import React, { Fragment } from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    Personal blog by <Styled.a href="https://twitter.com/aniruddha_verma">Aniruddha Verma</Styled.a>
    {` `}
    <br />
    Co-Founder CashPositive | YIF, SAP Labs, NITK Alumnus 
    <br />
    I write with rigor, for clarity.
  </Fragment>
)
